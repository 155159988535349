@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
  background: #FDFDFD;
}

.app {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}