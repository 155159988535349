.loaderStyles {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-inline-end: 0.75rem;
  color: white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
