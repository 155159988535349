:root {
  --heading: #393a56;
  --text: #464646;
  --theme: #fe5402;
  --bg: #fff;
}

.clipboard_container {
  width: 60%;
  display: flex;
  justify-content: center; 
  align-items: center;
}

.main_clipboard {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.clipboard {
  width: 100%;
  position: relative;
  background-color: #fdba74;
  color: #fff;
  border-radius: 25px;
  padding: 1em;
  margin-bottom: 1.5em;
  margin: auto 0;
}

.clipboard_btn {
  position: absolute;
  top: 1.1rem;
  right: 1rem;
  cursor: pointer;
  background-color: #fdba74;
  border: none;
  color: #fff;
}

.clipboard_btn:hover {
  color: #f97316;
}

.question_mark {
  background-color: #e5e7eb;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.question_mark:hover {
  background-color: #f3f4f6;
}

@media screen and (max-width: 480px) {
  .clipboard_container {
    width: 90%;
    /* margin-top: 8vh; */
    display: flex; /* Ensure the container uses flex layout */
    flex-direction: column; /* Stack children vertically */
  }

  .clipboard {
    display: flex;
    height: 50%;
    margin: 0;
  }
}

@media screen and (max-width: 480px) and (min-height: 701px) and (max-height: 830px) {
    .clipboard_container {
      width: 90%;
      margin-top: 6vh;
      display: flex; /* Ensure the container uses flex layout */
      flex-direction: column; /* Stack children vertically */
    }
  
    .clipboard {
      display: flex;
      height: 50%;
      margin: 0;
    }
}

@media screen and (max-width: 480px) and (max-height: 700px) {
    .clipboard_container {
      width: 90%;
      margin-top: 8vh;
      display: flex; /* Ensure the container uses flex layout */
      flex-direction: column; /* Stack children vertically */
    }
  
    .clipboard {
      display: flex;
      height: 50%;
      margin: 0;
    }
}
