/* Modal.module.css */

:root {
    --heading: #393a56;
    --text: #464646;
    --theme: #fe5402;
    --background-bulk: #fff8f5;
  }

.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    background-color: var(--background-bulk);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 600px;
    max-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .modal_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_header h2 {
    color: #464646;
    size: 36px;
    font-weight: 500;
    line-height: 54px;
  }

  .modal_header button {
    border: none;
    cursor: pointer;
    background-color: #f9eee9;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }

  .modal_header button:hover {
    background-color: #f1ac8e;
  }
  
  .submitBtn {
    display: flex;
    gap: 0.8rem;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    background-color: #ff4d4d;
  }

  .submitBtn .btnLabel {
    color: white;
  }

  .submitBtn .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .directBtn {
    background-color: #fff;
    color: #000;
    padding: 10px 20px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }

  .modal_inputs {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    margin-top: 2rem;
  }

  .name_field {
    width: 100%;
  }

  .input_field {
    width: 50%;
  }

  .form_zip_city {
    display: flex;
    gap: 2rem;
  }

  .modal_actions {
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
  }

  .modal_login {
    background-color: var(--background-bulk);
    /* padding: 20px; */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 800px;
    text-align: center;
    display: flex;
    flex-direction: column;
    /* border: 3px solid green; */
  }

  .modal_container{
    height: 100vh;
    width: 100vw;
  }

  .proceedBtn{
    width: 100%;
    background-color: var(--theme);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
  }

  .modal_actions a {
    text-decoration: none; 
    display: inline-block; 
    width: 50%;
  }