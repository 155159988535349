.return-policy {
  max-width: 900px;
  margin: 40px auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  line-height: 1.8;
  color: #333;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.return-policy h1 {
  text-align: center;
  font-size: 2.8em;
  color: #2c3e50;
  margin-bottom: 30px;
}

.return-policy h2 {
  font-size: 2em;
  margin-top: 30px;
  color: #34495e;
  border-bottom: 2px solid #ecf0f1;
  padding-bottom: 10px;
}

.return-policy h3 {
  font-size: 1.6em;
  margin-top: 25px;
  color: #7f8c8d;
}

.return-policy p {
  font-size: 1.1em;
  color: #555;
  margin-bottom: 15px;
}

.return-policy ul {
  list-style: disc inside;
  padding-left: 20px;
}

.return-policy ul li {
  margin: 12px 0;
  font-size: 1.1em;
  color: #444;
}

.return-policy address {
  font-style: normal;
  line-height: 1.6;
}

.return-policy strong {
  color: #2c3e50;
}

@media (max-width: 768px) {
  .return-policy {
    padding: 15px;
    max-width: 100%;
  }

  .return-policy h1 {
    font-size: 2em;
  }

  .return-policy h2 {
    font-size: 1.8em;
  }

  .return-policy p, 
  .return-policy ul li {
    font-size: 1em;
  }
}
