:root {
  --heading: #393a56;
  --text: #464646;
  --theme: #fe5402;
  --bg: #fff;
}

/* Header */
.login {
  background-color: var(--bg);
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-y: hidden;
}

.login_left {
  background: linear-gradient(-40deg, #e55812 40%, #ffffff 140%);
  width: 45vw;
  height: 100vh;
}

.login_left_header {
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_left_header h1 {
  color: #fff;
}

.login_right {
  margin: auto;
}
.login_form_heading{
  margin-top: -20px;
}

.brand_logo img {
  height: 120px;
  margin-top: -20px;
  
}

.login_form {
  width: 40vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
}

.textField,
.password_textField {
  width: 65%;
}

.password_textField {
  position: relative;
  display: flex;
  align-items: center;
}

.password_textField input {
  width: 100%;
  padding-right: 2.5rem; /* Adjust padding to make space for the icon */
}

.icon {
  position: absolute;
  right: 0.75rem; /* Adjust as needed */
  cursor: pointer;
  display: flex;
  align-items: center;
}

.button {
  width: 65%;
}

.google_button {
  width: 65%;
}

.button button {
  width: 100%;
  background-color: var(--theme);
  color: #fff;
  border: none;
  height: 4vh;
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

.google_button button {
  width: 100%;
  background-color: var(--bg);
  color: var(--theme);
  border: 0.5px solid var(--theme);
  height: 4vh;
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
}

.google_button button img {
  margin-left: 1rem;
  width: 1rem;
}

.line {
  display: flex;
  align-items: center;
  width: 65%;
}

.border_left,
.border_right {
  border: 0.2px solid #e5e7eb;
  width: 100%;
}

.line_or {
  font-size: small;
  padding: 0 10px;
}

.ellipses {
  position: relative;
  height: 100%;
}

.ellipses_main {
  /* background-color: pink; */
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute; /* or fixed */
  bottom: 25%;
}

.ellipses_main img {
  opacity: 0.5;
}

.ellipses_small {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute; /* or fixed */
  bottom: 33%;
  right: 12%;
}

.ellipses_small {
  opacity: 0.7;
}

.login_option {
    cursor: pointer;
    margin-left: 0.5rem;
}

.login_option:hover {
  text-decoration: underline;
}
