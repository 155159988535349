:root {
  --heading: #393a56;
  --text: #464646;
  --theme: #fe5402;
  --background-platform: #fff8f5;
}

.platform {
  background-color: var(--background-platform);
  width: 100vw;
  min-height: 100vh;
  padding-bottom: 5rem;
}

.platform_container {
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.platforms {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.platform__header {
  display: flex;
  background-color: #fff;
  width: 75%;
  border: none;
  border-radius: 1rem;
  gap: 6rem;
  padding: 1rem;
}

.platforms_info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 1rem;
  width: 75%;
}

.platforms_info p {
  color: #000;
}

.platforms_section {
  display: flex;
  width: 75%;
  gap: 1.5rem;
}

.filter_platforms {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.filter_name {
  background-color: #fff;
  border-radius: 1rem;
  padding: 12px 16px;
  text-align: center;
  cursor: pointer;
  width: 10rem;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.filter_name.active {
  border: 1px solid var(--theme);
}

.platforms_list {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  height: max-content;
}

.platform_item {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  width: 232px;
  height: 122px;
  border: 1px solid #fff;
  gap: 1rem;
  padding: 1rem;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.platform_link {
  display: flex;
  justify-content: space-between;
}

.platform_link p {
  color: #000;
}

.platform_link img {
  cursor: pointer;
  width: 1rem;
  height: 1rem;
}

.line {
  width: 100vw;
  height: 1px; 
  background-color: #00000033;
  margin-left: -50vw;
  position: relative;
  left: 50%;
}

.platform__footer {
  margin-top: 30px;
  display: flex;
  background-color: #fff;
  width: 100%;
  border: none;
  border-radius: 1rem;
  gap: 6rem;
  padding: 1rem;
}

.place_order_btn {
  width: 45%;
  height: 6vh;
  background-color: var(--theme);
  font-weight: 400;
  font-size: medium;
  border: none;
  cursor: pointer;
  border-radius: 8px;
}

.place_order_btn span {
  font-weight: 600;
  font-size: 1rem;
  line-height: 18px;
  text-align: center;
  color: #fff;
}
.platform_img img{
height: 56px;
max-width: 180px;
}
