:root {
  --heading: #393a56;
  --text: #464646;
  --theme: #fe5402;
}

ul {
  list-style: none;
}

.footer {
  background-color: #464646;
  width: 100vw;
  height: 70vh;
  display: flex;
  justify-content: center;
}

.footer_container {
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8vmax;
}
.footer_brand img{
  transition: transform 0.3s ease;
  transform: scale(1.5);
  height: 100px;
  margin-bottom: 20px;
  mix-blend-mode: multiply;
  z-index: 9999;

}

h3 {
    color: #fcfcfc;
    font-weight: 400;
    /* min-width: 150px; */
}

li {
  color: #fcfcfc;
  font-weight: 300;
}

.footer a {
  color: #fcfcfc;
  font-weight: 300;
}

.footer_content {
  display: flex;
  gap: 8vmax;
}

.footer_col {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.footer_col ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer_col ul li {
  cursor: pointer;
  font-size: 14px;
}


.footer_input {
  display: flex;
  gap: 0.2rem;
}

.input {
  padding: 0.7rem 0;
  text-indent: 0.5rem;
  outline: none;
  background-color: #7d7d7d;
  color: #fff;
  border: none;
  width: 15rem;
}

.input::placeholder {
  color: #fff;
}

.button {
  padding: 0.7rem;
  background-color: var(--theme);
  color: #fff;
  border: none;
}

.socials_list {
    display: flex;
    flex-direction: row !important; /* Ensure this is applied */
    gap: 1rem !important;
  }

.social_media {
    background-color: #7d7d7d;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.copyright {
  color: #fcfcfc;
  font-size: small;
  text-align: center;
  font-weight: 300;
}

@media screen and (max-width: 480px) {
    .footer {
      height: 90%;
    }

    .footer_content {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
    }
  
    .footer_content > div:nth-child(-n+2) {
      /* flex: 1 1 100%; */
      display: flex;
      flex-grow: 2;
      margin: 0 auto;
      align-items: flex-start;
    }

    .footer_content > :nth-child(3) {
      display: flex;
      width: 100%;
      text-align: start;
    }

    .footer_container {
      display: flex;
      flex-direction: column;
      gap: 5rem;
    }

    .footer_copyright {
      margin-bottom: 1rem;
    }

}

