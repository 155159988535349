/* Container styling */
.prohibited-items-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    line-height: 1.8;
    color: #333;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  /* Section heading */
  .prohibited-items-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #222;
  }
  
  .prohibited-items-container h2 {
    font-size: 20px;
    margin-top: 20px;
    color: #444;
  }
  
  /* Paragraph styling */
  .prohibited-items-container p {
    margin-bottom: 10px;
  }
  
  /* List styling */
  .prohibited-items-container ul {
    list-style-type: circle;
    margin-left: 20px;
    color: #555;
  }
  
  .prohibited-items-container ul li {
    margin-bottom: 8px;
    color: black;
  }
  
  /* Note styling */
  .prohibited-items-container .section:last-of-type p {
    font-weight: bold;
    color: #d9534f;
  }
  .section p{
    color: rgb(72, 69, 69);
  }

