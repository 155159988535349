:root {
  --heading: #393a56;
  --text: #464646;
  --theme: #fe5402;
  --background: #f3f4f6;
}

/* Categories.css */
.categories-section {
  padding: 20px;
}

.category-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 8px;
  height: 150px;
  font-size: 18px;
  color: #333;
  text-align: center;
  margin: 0 10px;
}

.slick-slide {
  padding: 10px;
}

.slick-dots li button:before {
  color: #333;
}
/* Custom arrow styles */
.arrow {
  color: grey;
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.next {
  right: -90px;
  background-color: rgb(243, 239, 239);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prev {
  background-color: rgb(243, 239, 239);
  left: -90px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  font-weight: bold;
  font-size: larger;
  align-items: center;
  justify-content: center;
}

.scroll_container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.sites_viewall {
  display: flex;
  gap: 0.5rem;
  width: 15vh;
  align-items: center;
  cursor: pointer;
  justify-content: end;
  color: #000;
}

.arrowIcon {
  width: 0.8rem;
  height: 0.8rem;
}

/*  */

p {
  color: var(--theme);
}

h2 {
    color: var(--text);
}

.categories {
  width: 100vw;
  max-height: 100vh;
  margin-top: 2rem;
}

.categories_container {
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
  gap: 2rem;
  /* background-color: pink; */
}

.categories_header {
  margin-top: 7rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

.categories_header .heading h2 {
  width: 40vw;
  text-transform: uppercase;
}

.categories_right {
  display: flex;
  gap: 4rem;
}

.categories_viewall {
  display: flex;
  gap: 0.5rem;
  width: 15vh;
  align-items: center;
  cursor: pointer;
  justify-content: right;
}

.arrowIcon {
    width: 0.8rem;
    height: 0.8rem;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.searchIcon {
  position: absolute;
  left: 1rem; /* Adjust based on your design */
  width: 0.8rem; /* Adjust size based on your design */
  height: 0.8rem; /* Adjust size based on your design */
}

.filterIcon {
    position: absolute;
    right: 1rem;
    cursor: pointer;
}

.input {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 12px;
  padding-left: 35px;
  outline: none;
  padding: 10px;
  width: 20rem;
  border: 1px solid #f3f4f6;
  text-indent: 1.6rem;
}

.categories_items {
    display: flex;
    gap: 18px;
    flex-wrap: wrap;
}

.category_item {
    background-color: #fff;
    width: 15.5vmax;
    height: 25vh;
    flex: 0 0 auto;     
    /* box-shadow: 0px 2px 23px 0px #0000001A; */
    cursor: pointer;
    width: 90% !important;
    border-radius: 8px;
    border: 1px solid rgba(231, 234, 236, 1);
    gap: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
}

.category_item:hover {
  transform: scale(1.1);
}

.category_item_image {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--theme);
    gap: 1rem;
}

.category_item_image img {
  /* width: 90%;
  height: 60%; */
  object-fit: cover; 
}

.category_item_image p {
  color: var(--theme);
  font-size: 1.1vmax;
  line-height: 30px;
  font-weight: 500;
}
.heading {
  width: 40vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}

@media screen and (max-width: 480px) {

  .categories {
    height: 90%;
  }

  .categories_container {
    width: 95%;
  }

  .categories_header {
    font-size: 5vw;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .sites_header h2 {
    margin: auto;
  }

  .categories_items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .categories_item {
    background-color: #fff;
    width: 80vw;
    height: 22vh;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin: auto;
  }
}
