:root {
    --heading: #393a56;
    --text: #464646;
    --theme: #fe5402;
    --background-account: #fff8f5;
  }
  
  .orders {
    background-color: var(--background-account);
    width: 100vw;
    /* height: 100vh; */
  }
  
  .orders_container {
    width: 80%;
    margin-left: 5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    /* background-color: pink; */
  }
  
  .orders_info {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .orders_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header_left {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  
  .header_left p {
    color: #475467CC;
  }
  
  .header_right button {
    background-color: var(--theme);
    color: #fff;
    border-radius: 8px;
    padding: 12px 24px;
    border: none;
    cursor: pointer;
  }

  .inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .searchIcon {
    position: absolute;
    left: 1rem; /* Adjust based on your design */
    width: 0.8rem; /* Adjust size based on your design */
    height: 0.8rem; /* Adjust size based on your design */
  }
  
  .filterIcon {
    position: absolute;
    right: 1rem;
    cursor: pointer;
  }
  
  .input {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 12px;
    padding-left: 35px;
    outline: none;
    padding: 10px;
    width: 20rem;
    border: 1px solid #f3f4f6;
    text-indent: 1.6rem;
  }

  .orders_list {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .order_info {
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
    border: 1px solid #F2FBF9;
    
  }

  .info_header {
    background: #F2FBF9;
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .info_header_left {
    display: flex;
    gap: 1rem;
  }

  .info_header_left_info {
    display: flex;
    flex-direction: column;
  }

  .info_header_left_top {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .total_price{
    color: #121025;
  }

  .header_info_tag {
    background-color: #676B78;
    padding: 4px 8px;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
  }

  .info_header_right {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .total_items {
    color: #121025;
    font-weight: 400;
    font-size: 15px;
  }

  .items_images {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .order_details {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 2rem;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .order_details_left {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 50%;
  }

  .order_number_container, .order_receiver_container, .order_eta_container, .order_service_container, .order_courier_container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .order_number_heading, .order_receiver_heading, .order_eta_heading, .order_service_heading, .order_courier_heading {
    color: #676B78;
    font-size: 13px;
    font-weight: 400;
  }

  .order_number, .order_receiver, .order_eta, .order_courier p{
    color: #121025;
    font-size: 15px;
    font-weight: 400;
  }

  .order_receiver, .order_eta, .order_courier {
    line-height: 24px;
  }

  .order_service {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .order_service span {
    color: #121025;
    font-size: 15px;
    font-weight: 400;
  }

  .order_details_right {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 40%;
  }