:root {
  --heading: #393a56;
  --text: #464646;
  --theme: #fe5402;
  --background-profile: #fff8f5;
}

.profile {
  background-color: var(--background-profile);
  width: 100vw;
  height: 100%;
  min-height: 100vh
}

.profile_container {
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.profile_tabs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.profile_tab {
  background-color: #fff;
  border-radius: 1rem;
  padding: 12px 16px;
  text-align: center;
  cursor: pointer;
  width: 12rem;
  border: 1px solid transparent;
}

.profile_tab.active {
  border: 1px solid var(--theme);
}

.selected_tab {
  display: flex;
  /* justify-content: center;
  width: 100%; 
  margin-top: 2rem; */
}

.line {
  width: 100vw;
  height: 1px; 
  background-color: #00000033;
  margin-left: -50vw;
  position: relative;
  left: 50%;
}

