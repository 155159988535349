:root {
    --heading: #393a56;
    --text: #464646;
    --theme: #fe5402;
    --background-platform: #fff8f5;
  }
  
  .possible {
    background-color: #fff;
    width: 100vw;
    height: fit-content;
    padding: 2rem 0 5rem 0;
  }

  .possible_container {
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 42px;
  }

  .possible_container h3 {
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    color: #464646;
}

.possible_steps {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
} 

.step_box {
    width: 48%;
    height: 177px;
    border-radius: 16px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid #FFFFFF;
    display: flex;
    gap: 1rem;
}

.step_left {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 70%;
    position: relative;
    left: 24px;
}

.box_step_number {
    width: 70px;
    height: 30px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 0.5rem;
    gap: 0.5rem;
    background-color: #464646;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box_step {
    width: 100%;
    height: 24px;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #475467;
}

.box_step_desc {
    height: fit-content;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: rgba(71, 84, 103, 0.8);
}

.step_right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 30%;
}

.step_right img {
    width: 10rem;
}
