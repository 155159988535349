/* General styling for the container */
.container{
    /* padding-left: 20px; */
}
.terms-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.8;
    color: #333 !important;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  /* Heading styles */
  .terms-container h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #222;
  }
  
  .terms-container h2 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #444;
  }
  
  /* Paragraph styling */
  .terms-container p {
    margin-bottom: 10px;
    color: #555;
  }
  
  /* List styling */
  .terms-container ul {
    list-style-type: circle;
    margin-left: 20px;
    padding-left: 0;
  }
  
  .terms-container ul ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  /* Contact info styling */
  .terms-container strong {
    font-weight: bold;
    color: #222;
  }
  
  /* Link styles (optional) */
  .terms-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .terms-container a:hover {
    text-decoration: underline;
  }
  .terms-container ul li{
    color: black !important;
  }