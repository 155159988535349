:root {
    --heading: #393a56;
    --text: #464646;
    --theme: #fe5402;
    --background-aboutUs: #f9f3ee;
  }
  
  .aboutUs {
    background-color: var(--background-aboutUs);
    width: 100vw;
    height: fit-content;
    /* padding-bottom: 5rem; */
  }
  
  .aboutUs_container {
    width: 85%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .items_container {
    width: 100vw;
    margin-left: -50vw;
    position: relative;
    left: 50%;
  }

  .line {
    width: 100vw;
    height: 1px; 
    background-color: #00000033;
    margin-left: -50vw;
    position: relative;
    left: 50%;
  }